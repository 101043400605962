import React from 'react';
import { Element } from 'react-scroll';

// Custom components
import Layout from '../components/layout';

// import Image from '../components/image';
import SEO from '../components/seo';

// Sections
import IndexLandingPageSection from '../sections/index/landing-page';
import IndexArticlesSection from '../sections/index/articles';
import IndexMenthorsSection from '../sections/index/menthors';
import IndexAboutUsSection from '../sections/index/about-us';
import IndexEventsSection from '../sections/index/events';
import Newsletter from '../components/shared/newsletter';

const IndexPage = () => {
  return (
    <Layout>
      <SEO title="Home" />
      <IndexLandingPageSection />
      <Element name="quienes-somos">
        <IndexAboutUsSection />
      </Element>
      <Element name="comunidad">
        <IndexMenthorsSection />
      </Element>
      <IndexArticlesSection />
      <Element name="eventos">
        <IndexEventsSection />
      </Element>
      <Newsletter />
    </Layout>
  );
};

export default IndexPage;
