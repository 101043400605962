import React from 'react';
import PropTypes from 'prop-types';
import { formatDistanceToNow } from 'date-fns';
import { es } from 'date-fns/locale';
import { Link } from 'gatsby';

// Material Design
import { Headline5, Body1, Body2 } from '@material/react-typography';
import { Cell } from '@material/react-layout-grid';

const parseDate = (date) => formatDistanceToNow(new Date(date), { locale: es });

const ArticleItem = ({
  title,
  abstract,
  authors,
  mainImage,
  createdAt,
  slug,
}) => (
  <>
    <Cell columns={6}>
      <img
        alt={mainImage.title}
        src={`https:${mainImage.fluid.src}`}
        className="mdc-article__image"
      />
    </Cell>
    <Cell columns={6}>
      <>
        <Body2>{parseDate(createdAt)}</Body2>
        <Headline5>
          <Link to={`articles/${slug}`}>{title}</Link>
        </Headline5>
        <Body1 className="mdc-typography--body1--abstract">
          <Link to={`articles/${slug}`}>{abstract}</Link>
        </Body1>

        <Cell columns={12}>
          <Body1 className="mdc-typography--body1--author">
            {authors
              .map(({ fullName }) => fullName)
              .reduce(
                (initial, currentValue, index) =>
                  `${initial}${
                    index === authors.length - 1 ? ' y ' : ', '
                  } ${currentValue}`,
              )}
          </Body1>
        </Cell>
      </>
    </Cell>
  </>
);

ArticleItem.propTypes = {
  mainImage: PropTypes.shape({
    fluid: PropTypes.object,
    title: PropTypes.string,
  }),
  title: PropTypes.string,
  abstract: PropTypes.string,
  authors: PropTypes.arrayOf(PropTypes.object),
  createdAt: PropTypes.string,
  slug: PropTypes.string,
};

export default ArticleItem;
