import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

// Material Design
import { Headline3 } from '@material/react-typography';
import { Grid, Cell, Row } from '@material/react-layout-grid';

// Components
import MenthorCard from '../../components/menthors/menthor-card';

const menthorsQuery = graphql`
  query menthorsQuery {
    allContentfulMentora(limit: 6) {
      nodes {
        id
        fullName
        ocupation
        quote
        avatar {
          title
          fluid(maxWidth: 300) {
            src
          }
        }
      }
    }
  }
`;

const IndexMenthorsSection = () => {
  const data = useStaticQuery(menthorsQuery);

  return (
    <>
      <Grid className="mdc-section--community">
        <Row>
          <Cell columns={12}>
            <Headline3 style={{ marginBottom: 200 }}>
              Comunidad CMujer
            </Headline3>
          </Cell>
        </Row>
        <Row>
          <Cell columns={1} />
          <Cell columns={10}>
            <Row>
              {data.allContentfulMentora.nodes.map((menthor) => (
                <MenthorCard
                  fullName={menthor.fullName}
                  key={menthor.id}
                  avatar={menthor.avatar}
                  ocupation={menthor.ocupation}
                  quote={menthor.quote}
                />
              ))}
            </Row>
          </Cell>
        </Row>
      </Grid>
    </>
  );
};

export default IndexMenthorsSection;
