import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

// Material Design
import { Headline3, Headline6 } from '@material/react-typography';
import { Grid, Cell, Row } from '@material/react-layout-grid';

const aboutUsQuery = graphql`
  query aboutUsQuery {
    contentfulSobreNosotros {
      image {
        title
        fluid(maxWidth: 1000, quality: 100) {
          src
        }
      }
      title
      description
    }
  }
`;

const IndexAboutUsSection = () => {
  const { contentfulSobreNosotros: aboutUs } = useStaticQuery(aboutUsQuery);

  return (
    <>
      <Grid className="mdc-section--about-us">
        <Row>
          <Cell columns={12} desktopColumns={12} phoneColumns={4}>
            <Headline3 style={{ marginBottom: 200 }}>{aboutUs.title}</Headline3>
          </Cell>
        </Row>
        <Row>
          <Cell columns={2} desktopColumns={2} phoneColumns={4} />
          <Cell columns={8} desktopColumns={8} phoneColumns={4}>
            <Row style={{ marginBottom: 50 }}>
              <Cell columns={6} phoneColumns={4}>
                <Headline6>{aboutUs.description}</Headline6>
              </Cell>
              <Cell columns={6} phoneColumns={4}>
                <img alt={aboutUs.image.title} src={aboutUs.image.fluid.src} />
              </Cell>
            </Row>
          </Cell>
        </Row>
      </Grid>
    </>
  );
};

export default IndexAboutUsSection;
