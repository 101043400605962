import React from 'react';
import PropTypes from 'prop-types';

// Material Design
import { Cell, Row } from '@material/react-layout-grid';
import { Body1, Headline5, Headline6 } from '@material/react-typography';

const MenthorCard = ({ fullName, avatar, ocupation, quote }) => (
  <Cell columns={4} desktopColumns={4} phoneColumns={4} tabletColumns={4}>
    <div className="mdc-section__menthor-card--avatar">
      <img
        className="avatar-image"
        alt={avatar.title}
        src={`https:${avatar.fluid.src}`}
      />
      <div className="mdc-section__menthor-card--avatar__circle" />
    </div>

    <Row>
      <Cell columns={2} phoneColumns={4} />
      <Cell columns={8} phoneColumns={4}>
        <Headline5>{fullName}</Headline5>
        <Headline6>{ocupation}</Headline6>

        <Body1>{`"${quote}"`}</Body1>
      </Cell>
    </Row>
  </Cell>
);

MenthorCard.propTypes = {
  fullName: PropTypes.string,
  ocupation: PropTypes.string,
  quote: PropTypes.string,
  avatar: PropTypes.shape({
    fluid: PropTypes.object,
    title: PropTypes.string,
  }),
};

export default MenthorCard;
