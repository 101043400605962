import React, { useState, useEffect } from 'react';
import { Link } from 'gatsby';
import { format } from 'date-fns/';
import { es } from 'date-fns/locale';
import axios from 'axios';

// Material Design
import { Grid, Row, Cell } from '@material/react-layout-grid';
import {
  Headline3,
  Headline4,
  Headline5,
  Body1,
} from '@material/react-typography';

// Imagenes
import banner from '../../images/banner.png';

const IndexEventsSection = () => {
  const [events, setEvents] = useState([]);

  const getEvents = async () => {
    const response = await axios({
      method: 'GET',
      headers: {
        Authorization: `Bearer ${process.env.GATSBY_EVENTBRITE_PRIVATE_API_TOKEN}`,
      },
      url: 'https://www.eventbriteapi.com/v3/users/me/events/',
    });

    setEvents(response.data.events);
  };

  useEffect(() => {
    getEvents();
  }, []);

  return (
    <div className="mdc-section--events">
      <Grid>
        <Row>
          <Cell columns={12}>
            <Headline3>Eventos</Headline3>
          </Cell>
        </Row>

        <Row>
          <Cell columns={3} />
          <Cell columns={6}>
            <Row>
              <Cell columns={12}>
                <Link to="/seminario-nacional-de-mujeres-2020/registro">
                  <img
                    alt="Banner de evento"
                    src={banner}
                    style={{ width: '100%' }}
                  />
                </Link>
              </Cell>
              <br />
              <br />
              <br />
              {events &&
                events.map((event) => (
                  <Cell key={event.id} columns={12}>
                    <a
                      href={event.url}
                      rel="noreferrer noopener"
                      target="_blank"
                    >
                      <img
                        className="mdc-section--events__img"
                        alt={event.name.text}
                        src={event.logo.original.url}
                      />
                      <Body1>
                        {format(new Date(event.start.local), 'PPPP', {
                          locale: es,
                        })}
                      </Body1>
                      <Headline4>{event.name.text}</Headline4>
                      <Headline5>
                        La Calera, 3a Privada de 13 de Septiembre #100, Fracc.
                        Niños Héroes, Oaxaca, Oax.
                      </Headline5>
                    </a>
                  </Cell>
                ))}
            </Row>
          </Cell>
        </Row>
      </Grid>
    </div>
  );
};

export default IndexEventsSection;
