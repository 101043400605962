import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

// Material Design
import { Grid, Row, Cell } from '@material/react-layout-grid';
import { Headline3 } from '@material/react-typography';

// Components
import ArticleItem from '../../components/articles/article-item';

const articlesQuery = graphql`
  query articlesQuery {
    allContentfulArticulo {
      nodes {
        id
        slug
        title
        authors {
          fullName
          id
          ocupation
        }
        categories {
          slug
          title
        }
        abstract
        createdAt
        mainImage {
          fluid(quality: 100, maxWidth: 1000) {
            src
          }
        }
      }
    }
  }
`;

const IndexArticlesSection = () => {
  const {
    allContentfulArticulo: { nodes: articles },
  } = useStaticQuery(articlesQuery);

  return (
    <Grid className="mdc-section--articles">
      <Row>
        <Cell columns={12}>
          <Headline3>Palabras de mujer</Headline3>
        </Cell>
      </Row>
      <Row>
        <Cell columns={2} />
        <Cell columns={8}>
          {articles.map((article) => (
            <Row className="mdc-article" style={{ marginBottom: 100 }}>
              <ArticleItem
                key={article.id}
                slug={article.slug}
                title={article.title}
                abstract={article.abstract}
                authors={article.authors}
                mainImage={article.mainImage}
                createdAt={article.createdAt}
              />
            </Row>
          ))}
        </Cell>
      </Row>
    </Grid>
  );
};

export default IndexArticlesSection;
