import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

// Material components
import { Cell, Grid, Row } from '@material/react-layout-grid';
import { Headline3, Headline5 } from '@material/react-typography';

// Query
const landingpageQuery = graphql`
  query landingpageQuery {
    contentfulPaginaDeAterrizaje {
      title
      gallery {
        fluid(maxWidth: 1400) {
          src
        }
        title
      }
      subtitle
    }
  }
`;

const IndexLandingPageSection = () => {
  const { contentfulPaginaDeAterrizaje: landingPage } = useStaticQuery(
    landingpageQuery,
  );

  return (
    <Grid className="mdc-landing">
      <Row>
        <Cell
          columns={5}
          desktopColumns={5}
          tabletColumns={4}
          phoneColumns={4}
          style={{
            display: 'flex',
            alignItems: 'center',
            height: 'calc(100vh - 200px)',
          }}
        >
          <div>
            <Headline3>{landingPage.title}</Headline3>
            <Headline5>{landingPage.subtitle}</Headline5>
          </div>
        </Cell>
        <Cell columns={7} desktopColumns={7} phoneColumns={4} tabletColumns={4}>
          <div>
            <img
              className="mdc-landing__image"
              alt={landingPage.gallery.title}
              src={`https:${landingPage.gallery[0].fluid.src}`}
            />
          </div>
        </Cell>
      </Row>
    </Grid>
  );
};

export default IndexLandingPageSection;
